body{
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
/* nav& header */
.nav-tpp {
  display: flex;
  justify-content: end;
}
.logo {
  position: relative;
  z-index: 3;
}

.account, .fav, .cart{
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  box-shadow: -3px 10px 15px #ccc;
  margin-right: 10px;

}
.images-main img {
  height: 415px;
  object-fit: cover;
}
.more-imgs img {
  height: 204px;
  object-fit: cover;
}
.more-imgs {
  height: 417px;
  overflow-y: scroll;
}
.link-sign{
  text-decoration: none;
}
.account{
  width: 103px;
  text-decoration: none !important;
  color: #000;
}
header {
  box-shadow: 0 4px 24px #e5e5e5;
}
.logo::before {
  content: '';
  background-color: #fff;
  padding: 24px;
  -webkit-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  transform: skewX(330deg);
  position: absolute;
  width: 300px;
  height: 133px;
  z-index: -1;
  left: -110px;
}
.nav-head {
  padding: 21px;
}
header .top-cont {
  display: flex;
  justify-content: space-between;
}
button.btn.btn-outline-primary.phno {
  margin-right: 12px;
}
button.btn.btn-outline-primary {
  border-color: #f4652f;
  color: #f4652f;
}
button.btn.btn-outline-primary:hover {
  background-color: transparent
 
}
.drop-list .dropdown-menu.show a{
  margin-bottom: 10px;
  font-weight: 600;
}
.drop-list .dropdown-menu.show a:hover{
  background-color: #ffddd0;
}
.drop-list .dropdown-menu.show{
  margin-top: 6px;
  background-color: #ffefeb;
  border: 0;
}
button.btn.btn-outline-primary:focus{
  box-shadow: unset !important;
}
button.btn.btn-secondary{
  background-color: #f4652f;
  color: #fff;
  border: 0;
  padding: 10px 20px;
}
.navbar-menu {
  margin-top: -47px;
  background-color: #FEF0EB;
  z-index: 2;
  position: relative;
}
ul.navbar-nav {
  margin-right: 67px;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-size: 15px;
}
.navbar-light .navbar-nav .nav-link.active{
  font-weight: 700;
}

/* footer */
footer{
  background-color: #0D0D0D;
  padding: 72px 0 30px 0;
}
.looking-plan input {
  background-color: transparent;
  border: 1px solid #707070 !important;
  width: 64%;
}
footer ul{
  text-align: left;
}
.logo-footer {
  text-align: left;
}
footer h5{
  text-align: left;
}
.looking-plan{
  text-align: left;
}
.copyryt {
  margin-top: 19px;
}
footer ul li{
  list-style-type: none;
  margin-bottom: 7px;
}
footer ul {
  padding-left: 0;
}
footer ul li a {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
}
footer ul li a:hover{
  color: #fff !important;
}
.call-info a:hover{
  color: #fff !important;
}
.looking-plan h3 {
  font-size: 23px;
  color: #fff;
 
}
.input-subs input {
  background-color: transparent;
  border: 1px solid #717171 !important;
}
.copyryt p {
  color: #4b4b4b;
  font-size: 15px;
}
.call-info {
  display: flex;
  align-items: flex-start;
}
.call-info p{
  color: #fff;
}
.call-info{
  margin-bottom: 10px;
}
.call-info a {
  color: #fff;
  text-decoration: none;
}
.call-info img {
  margin-right: 7px;
}
.looking-plan button {
  margin-top: 22px;
}
footer h5 {
  font-size: 14px;
  color: #f4652f;
  text-transform: uppercase;

  margin-bottom: 20px;
}
.social-media a{
  margin-right: 10px;
}

/* login */
.btn-login {
  background-color: #F46530 !important;
  border: 0 !important;
}
.btn-login:hover{
  background-color: #F46530 !important;
}
.btn-login:focus{
  box-shadow: unset !important;
}
.pt-content {
  background-color: #FEF0EB;
}
.btn-login {
  width: 100%;
  padding: 12px !important;
}
.pt-content ul li {
  font-size: 15px;
  margin-bottom: 20px;
}
.pt-content ul{
  padding: 0rem;
}
.login-content{
  padding-top: 96px;
  padding-bottom: 83px;
}
button.btn.btn-light {
  background-color: #F3F3F3;
}
.login-form h2 {
  margin-bottom: 25px;
  margin-top: 35px;
}
.login-form p a {
  color: #000;
}
hr {
  height: 1px;
  width: 37%;
  
}
.login-form {
  padding: 0 3rem;
}
.lost-psd.text-center {
  margin-top: 20px;
}
.lost-psd.text-center a {
  color: #f4652f;
}
.social-media-btns button img {
  margin-right: 13px;
}
.social-media-btns button {
  width: 100%;
  margin-bottom: 12px;
  padding: 11px;
}
.signin{
  display: flex;
  margin: 20px 0;
}
.signin span {
  margin: 0 16px;
  font-size: 16px;
}

/* style="mask-type:alpha" */
.mask-alpha {
  mask-type: alpha;
}

.error {
  color: red;
}

.custom-link {
  text-decoration: none;
  color: inherit; /* Optional: This will keep the text color the same as the surrounding text */
}


section.herobanner {
  background-image: url("../images/herobanner.png"); /* The image used */
background-color: #cccccc; /* Used if the image is unavailable */
background-position: center; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */
background-size: cover; /* Resize the background image to cover the entire container */
padding: 241px 0;
}
section.herobanner h2 {
  text-align: center;
  color: #fff;
  font-size: 49px;
  font-weight: 600;
  margin-bottom: 32px;
}
.banner-search .form-select {
  padding: 11px;
}
.banner-search {
  display: flex;
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.banner-search .bed {
  max-width: 250px;
  width: 100%;
  margin-right: 15px;
}
.features-wrap .history h5 {
  margin: 21px 0px;
  font-size: 20px;
  font-weight: 600;
}

.features-wrap .history {
  max-width: 42%;
  margin-left: 37px;
  margin-bottom: 36px;
}
.feature{
  padding: 80px 0;
}
.features-wrap {
  display: flex;
  flex-wrap: wrap;
}
.pt-content {
  background-color: #FEF0EB;
  padding: 38px 38px 0px 38px;
}
.pt-content p {
  font-size: 14px;
}
.pt-content h3 {
  font-size: 32px;
  font-weight: 600;
}
.pt-content img {
  margin-right: 35px;
}
.h-details hr {
  height: 23px;
  width: 1px;
}
.h-details {
  display: flex;
}
.img-content {

  padding: 18px;
}
.h-details .area{
  text-align: center;
}
.area p {
  font-size: 15px;
}
.h-details hr {
  margin: 0 12px;
}
.wrap > img {
  position: relative;
}
.sociali-btn {
  position: absolute;
  top: 15px;
  right: 7px;
  display: flex;
}
.pln-no .price {
  font-weight: 800;
  color: #f4652f;
}
.img-h{
  position: relative;
  padding-top: 20px;
}

.img-h img {
  object-fit: cover;
  
}
section.new-plans {
  background-color: #FEF0EB;
}
.img-h img {
  border-radius: 10px 10px 0 0;
}
section.new-plans {
  padding: 80px 0;
}
.top-heading h4 {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 48px;
}
.top-heading p {
  color: #f4652f;
  font-size: 20px;
}
.top-heading {
  text-align: center;
}
button.buttonBack___1mlaL.carousel__back-button {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 37%;
  left: 0;
}
button.buttonNext___2mOCa.carousel__next-button{
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 37%;
  right: 0;
}
.all-location {
  text-align: center;
}
.youtube {
  margin-right: 10px;
  margin-top: -3px;
}
.row{
  --bs-gutter-x: 0px !important;
}
.img-banner img {
  width: 100%;
}
.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-image: linear-gradient( #f4643045 , #F46530);
}
.img-wrap:hover .overlay{
  opacity: 1;
}

.img-wrap .img-text {
  position: absolute;
  bottom: 0;
  left: 4%;
  color: #fff;
}
.coll1 button svg {
  fill: #f4652f;
}
.coll1 span {
  margin-left: 17px;
  margin-right: 17px;
}

.coll1 {

  background-color: #fff;
  border-radius: 7px;
  padding: 11px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
}
.coll1 button{
  margin: 5px 0;
}
.cont-details {
  display: flex;
}
.btm-butn button {
  background-color: #f4652f;
  border: 0;
}
.ourprize{
  margin-bottom: 5rem;
}
.btm-butn button:hover{
  background-color: #f4652f;
  border: 0;
}
.cont-details {
  display: flex;
  margin: 22px 0px;
}
.left-ban-text h4 {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 22px;
}
.left-ban-text {
  text-align: justify;
}
.cont-details div {
  background-color: #fff;
  padding: 11px;
  margin-right: 20px;
  border-radius: 10px;
}
section.ourprize .container {
  padding: 4rem;
}
.fil-title {
  justify-content: space-between;
  display: flex;
}
.typeflor h5{
  text-align: left;
}
section.ourprize .container {
  background-color: #FEF0EB;
  border-radius: 18px;
}
.collections .coll1 {
  margin-right: 11px;
}
.collections {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.coll1 button:hover{
  background-color: #f4652f !important;
}
.coll1 button:hover svg{
  fill: #fff;
}
.img-wrap .img-text p{
  font-weight: 600;
  font-size: 22px;
}
section.gallery {
  padding: 70px 0;
}
.gallery .all-location {
  margin-top: 3rem;
}
.img-wrap {
  position: relative;
  padding: 1px;
}
.banner-lower p {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.banner-lower {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.img-banner{
  position: relative;
}
.img-banner .banner-text {
  background-color: #fff;
  max-width: max-content;
  padding: 12px 20px;
  position: absolute;
  top: 17px;
  left: 20px;
  border-radius: 10px;
}
.ex-tag {
  margin-top: -20px;
  z-index: 2;
  position: relative;
  text-align: left;
}
.img-wrap img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.pln-no h2 {
  font-size: 25px;
}
.wrap {
  max-width: 333px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px #e0e0e0 !important;
  margin-right: 10px;
  margin-bottom: 33px;
}
.img-content > hr {
  width: 100%;
}

.florpln button {
  padding: 8px 20px;
  margin-left: 10px;
}
.florpln button:hover{
  background-color: transparent;
  color: rgb(75, 75, 75);
}
.filters {
  background-color: #F6F6F6;
  margin: 18px;
  border-radius: 8px;
  padding: 17px;
}
.fil-title span {
  margin-left: 9px;
}
.typeflor {
  margin-top: 20px;
}
.typeflor button{
  padding: 10px 30px !important;
}
.keyword form input {
  width: 85%;
}
.search button.accordion-button img {
  margin-right: 6px;
}
.keyword form button{
  width: 15%;
}
.typeflor h5 {
  font-size: 17px;
}
.keyword {
  margin-top: 19px;
}
.keyword label{
  font-weight: 600;
}
.total-area input{
  width: 30%;
}
.d-flex.total-area input {
  margin-right: 7px;
}
.search button.accordion-button{
  background-color: #fff;
  color: #000;
}
.all-options span {
  color: #f4652f !important;
  font-weight: 600;
  margin-left: 10px;
}
.plans-farms {
  display: flex;
  flex-wrap: wrap;
}
.search .wrap{
  max-width: 282px;
  margin-right: 6px;
  margin-bottom: 8px;
}
.search{
  padding-top: 70px;
}
.farmplans {
  background-color: #FEF0EB;
  border-radius: 7px;
  padding: 15px;
  margin-top: 18px;
  margin-bottom: 20px;
}
.search .wrap img{
  width: 100%;
}
.search .ex-tag img{
  width: 30% !important;
}
.search .h-details .area strong{
  font-size: 13px;
}
.search .h-details .area p {
  font-size: 12px;
}
.search .pln-no h2 {
  font-size: 18px;
}

.contact-details .email {
  display: flex;

}
.contact-details .email span {
  font-size: 13px;
}
.contact-details .email p {
  font-size: 17px;
  font-weight: 500;
}
.contact-details .email {
  box-shadow: 0 0 3px #ccc;
  max-width: 401px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 6px;
  margin: 22px 0;
}
section.contact {
  padding-bottom: 50px;
}
.contact-details h3 {
  margin-bottom: 43px;
}
.contact-details h3 {
  margin-bottom: 43px;
  font-size: 26px;
  font-weight: 400;
}
.contact-form {
  padding: 40px 40px;
  background-color: #FEF0EB;
  border-radius: 8px;
}
.contact-form button{
  margin-top: 30px;
}


section.ourstory .row {
  background-color: #FEF0EB;
  padding: 3rem;
  border-radius: 16px;
  margin-top: 3rem;
}
section.ourstory h2{
  text-align: center;
  font-size: 42px;
}

.content-wrap h4 {
  font-size: 29px;
  font-weight: 700;
  padding-right: 5rem;
  margin-bottom: 25px;
}
.content-wrap p {
  font-size: 15px;
  padding-right: 6rem;
}
section.ourstory h2{
  margin-top: 4rem;
}
.top-heading h4 {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 48px;
}
.team-wrap strong {
  font-size: 23px;
}
.team-wrap p {
  margin-top: 19px;
  font-size: 14px;
}
.team-wrap h6 {
  color: #F46530;
  text-align: center;
  margin: 6px 0;
}
section.team-members .top-heading {
  margin-bottom: 89px;
}
section.team-members .col-lg-4 {
  display: flex;
  justify-content: center;
}
section.team-members {
  padding-top: 6rem;
}
.team-wrap p {
  text-align: center;
}
.team-wrap {
  max-width: 386px;
  width: 100%;
  box-shadow: 0 10px 30px #eaeaea;
  padding: 34px;
  margin-bottom: 30px;
  margin-top: 54px;
}
.team-wrap {
  max-width: 386px;
  width: 100%;
}
.team-img strong {
  margin-top: 20px;
}
.team-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -78px;
}
.top-heading p {
  color: #f4652f;
  font-size: 20px;
}
.top-heading {
  text-align: center;
}
.all-location{
  margin: 3rem 0;
}

/* Product Details Css */
.row.prop-imggal img {
  width: 100%;
}
.wrap-img-gal {
  padding: 6px;
}
.wrap-img-gal.img-gal {
  position: relative;
}
.viewall {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.viewall button {
  background-color: #fff;
}
.viewall button:hover{
  background-color: #fff !important;
}
.viewall button:focus{
  background-color: #fff !important;
}
.option-details .detial {
  display: flex;
}
.print {
  margin-right: 12px;
  cursor: pointer;
}
.print img{
  margin-right: 5px;
}
.share {
  display: flex;
}
.option-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.galry-co {
  display: flex;
  justify-content: space-between;
}
.about-det {
  display: flex;
}

.galry-co a {
  color: #F46530;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 20%;
}
.galry-co p {
  margin-right: 20px;
}
.paln-wrap ul, .plan-details ul {
  padding-left: 13px;
  margin-right: 20px;
}
.price strong {
  font-size: 20px;
  color: #F46530;
  font-weight: 700;
}
.start-plan {
  margin: 0 24px;
  padding: 18px;
  box-shadow: 0 4px 24px #ccc;
}
.start-plan {
  padding: 22px;
}
.price span {
  color: #999;
}
.paln-wrap h4 {
  margin-bottom: 30px;
}
.paln-wrap ul li, .plan-details ul li {
  font-size: 14px;
  margin-bottom: 13px;
}
.plan span {
  color: #F46530;
  font-weight: 600;
}
.mainlevle img {
  margin-right: 7px;
}
.mainlevle {
  display: flex;
  justify-content: space-between;
  margin: 13px 0;
}
.flor-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 23px;
}
.plan h5 {
  font-size: 15px;
}
.plan {
 
  background-color: #ffefe8;
  width: 100%;
  padding: 7px;
  margin: 20px 0;
}
.about-det hr {
  height: 31px;
  width: 1px;
  margin-right: 4%;
}
.about-det .sqft {
  width: 166px;
}
section.productinternal {
  padding-top: 60px;
}
.start-plan button {
  width: 100%;
  margin-top: 20px;
}
.start-plan label {
  font-weight: 600;
}
.cost {
  padding: 39px;
  margin: 0 24px;
  background-color: #FEF0EB;
  
}
.cost h4 {
  font-size: 20px;
}
.cost p{
  font-size: 14px;
}
.cost button {
  width: 100%;
}
.modify-plan p {
  font-size: 15px;
  color: #fff;
}
.modify-plan h4 {
  color: #fff;
  font-weight: 700;
}
.modify-plan{
  padding: 39px;
  margin: 30px 24px; 
  background-color: #F46530;
  border-radius: 10px;
}
.sq-brkdown{
  padding: 39px;
  margin: 30px 24px; 
  background-color: #fff;
  box-shadow: 0 4px 24px #ccc;
  border-radius: 10px;
}
.total-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.ft {
  color: #f4652f;
  background-color: #ffe4da;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: 700;
}
section.viewallphotos {
  padding-top: 80px;
}
.full-image img {
  width: 100%;
  border-radius: 10px;
}
.otherimg img {
  width: 100%;
}
.otherimg {
  padding: 14px 9px;
}
.other-ingo {
  padding: 28px;
  margin: 4px 30px 24px;
  background-color: #F46530;
  border-radius: 15px;
  color: #fff;
}
.price-g {
  text-align: center;
  text-decoration: underline;
  margin: 16px 0;
}
.viewallphotos .d-flex.modal-footer button{
width: 50% !important;
}
button.close-foot.btn.btn-secondary {
  background-color: transparent;
  border: 1px solid #f4652f;
  color: #f4652f;
}
.viewallpics {
  display: flex;
  flex-direction: column;
}
.viewallpics a{
  margin-bottom: 15px;
}

/* CopyRight */
.notice {
  box-shadow: 0 0 3px #ccc;
  padding: 23px;
}
.notice p{
  font-size: 18px;
}

.notice p {
  font-size: 14px;
}
section.copyright {
  padding-bottom: 61px;
}

/* FAQ */
section.faq .accordion button {
  background-color: transparent;
  /* color: #f4652f; */
  box-shadow: unset !important;
}
section.faq .accordion-header{
  border-bottom: 0 !important;
}
.accordion-item {
  margin-bottom: 20px;
  border-top: 1px solid #e2e2e2 !important;
}
section.faq {
  padding-bottom: 50px;
}
section.faq .accordion-button:not(.collapsed)::after{
  background-image: url("../images/minus.svg")
}
section.faq .accordion-button:not(.collapsed){
  color: #f4652f !important;
}
section.faq .accordion-button::after{
  background-image: url("../images/Expand\ Item.svg")
}

/* User Settings */

section.usersettings .form {
  max-width: 50%;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px #ccc;
  padding: 45px 47px;
  margin-bottom: 51px;
}
section.usersettings h2 {
  text-align: center;
  font-size: 49px;
  font-weight: 700;
  margin-bottom: 39px;
  margin-top: 46px;
}
.email-subscription h3 {
  font-weight: 600;
}
.email-subscription .d-flex {
  margin: 30px 0;
}
.form-check-input:checked{
  background-color: #F46530 !important;
  border-color: #F46530 !important;
}
.email-subscription {
  margin-top: 40px;
}
.email-subscription span {
  color: #F46530;
  text-decoration: underline;
  cursor: pointer;
}
.fal-add-cart div h5 {
  color: #f4652f;
}

/* Favourite List */
section.favourite .top-title {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
}
section.favourite .top-title h2 {
  font-size: 45px;
}
.cat-content {
  display: flex;
  justify-content: space-between;
  padding: 13px 21px 0;
}
a{
  color: #000;
  text-decoration: none;
}
.text-center.top-tiles {
  margin: 50px 0;
}
a:hover{
  color: #000;
  text-decoration: none;
}
section.favourite .row {
  margin-bottom: 100px;
}
.cat-content img:last-child {
  margin-right: -34px;
}
.cat-content img {
  width: 55%;
}
.fav-category > img {
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.fav-category {
  background-color: #fff;
  box-shadow: 0 0 20px #ccc;
  border-radius: 14px;
}
.cat-content p {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cat-content span {
  font-size: 16px;
}
.modal-footer button {
  padding: 9px;
}
.favourite .modal-footer {
  flex-direction: column;
}
.favourite .modal-footer button{
  width: 100%;
}
.del .modal-footer button {
  width: 48%;
}
.del .modal-footer {
  display: inline-flex;
}
.fav-cover span {
  background-color: #FEF0EB;
  color: #f4652f;
  padding: 6px 10px;
  font-weight: 600;
}
.modal-footer .btn-primary{
  background-color: #F46530;
  border: 0
}
.modal-footer .btn-outline-secondary:hover{
  background-color: transparent;
  color: #000;
}
.fav-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Pricing */
.top-heading h2 {
  font-size:50px;
  font-weight: 600;
  margin-bottom: 48px;
}
section.team-members .top-heading {
  margin-bottom: 89px;
}
.top-heading p {
  color: #f4652f;
  font-size: 20px;
}
.top-heading {
  text-align: center;
}

/* Reviews */
.top-heading h2 {
  font-size:50px;
  font-weight: 600;
  margin-bottom: 48px;
  margin-top: 71px;
}
section.review {
  padding: 20px 0 60px 0;
}
.rev-wrap {
  border: 1px solid #DDE1E6;
  border-radius: 11px;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  margin-bottom: 30px;
  margin: 0 10px;
}
.review-cover {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.review p {
  text-align: center;
}
.rating {
  margin-bottom: 18px;
}

/* Testimonial */
.top-heading h2 {
  font-size:50px;
  font-weight: 600;
  margin-bottom: 48px;
}
.wrap > img {
  position: relative;
}
.wrap {
  max-width: 333px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 30px #e0e0e0;
}
.sociali-btn {
  position: absolute;
  top: 15px;
  right: 7px;
  display: flex;
}
.img-content {

  padding: 18px;
}
.pln-no .price {
  font-weight: 800;
  color: #f4652f;
}
.content-review {
  padding: 5rem 2rem;
  background-image: url("../images/qoute.png");

  background-repeat: no-repeat;
  background-position: right 4% top 18%;

}
section.testimonial {
  padding-bottom: 70px;
}
.review-wrap {
  background-color: #fff;
  box-shadow: 0 0 14px #e5e5e5;
}
.content-review p {
  padding-right: 7rem;
}
.review-wrap {
  display: flex;
}

/* Cart */
.wrap > img {
  position: relative;
}
.wraps {
  max-width: 333px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;

}
.cart-content.check-acrt {
  width: 43%;
}
.subtotal.check-total {
  width: 100%;
}
.review-wrap.check-wrap .wraps p {
  font-weight: 600;
  padding-left: 22px;
}
.review-wrap.check-wrap .wraps{
  max-width: unset !important;
  padding-right: 20px;
}
.order-summary {
  background-color: #fff;
  margin-left: 18px;
  box-shadow: 0 0 14px #e5e5e5;
  padding: 27px;
}
.d-flex.total-sum.justify-content-between{
  color: #f4652f;
}
span.edit-link{
  color: #f4652f;
  text-decoration: underline;
  cursor: pointer;
}
.d-flex.top-h span {
  color: #f4652f;
  text-decoration: underline;
  cursor: pointer;
}
.email-details .d-flex {
  margin-bottom: 8px;
}
.d-flex.top-h {
  justify-content: space-between;
  margin-bottom: 20px;
}
.review-wrap.check-wrap{
  justify-content: space-between;
}
.carts{
  padding-bottom: 70px;
}
.tax.final-total {
  color: #f4652f;
}
.sociali-btn {
  position: absolute;
  top: 15px;
  right: 7px;
  display: flex;
}
.fal-add-cart {
  display: flex;
  padding: 1rem 11px;
  background-color: #FEF0EB;
  border-radius: 7px;
  margin: 20px 1px;
}
.fal-add-cart div {
  padding: 0 36px;
}
hr.divider {
  width: 1px;
  height: 100%;
  margin-right: 19px;
}
p.sbuild {
  font-size: 15px;
}
.more-detail {
  width: 75%;
  padding-right: 26px;
}
.pdf {
  display: flex;
}
.more-detail h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 33px;
}
.review-wrap {
  display: flex;
  padding: 24px;
  margin-bottom: 3rem;
}
.subtotal {
  width: 25%;
}
.subtotal hr {
  width: 100%;
}
.cart-total .tax {
  display: flex;
  justify-content: space-between;
}
.pdf {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cart-content {
  display: flex;
  padding: 30px;
}
.fal-add-cart hr {
  height: 20px;
  width: 1px;
}



section.costbuild h2 {
  margin: 43px 0;
  font-size: 44px;
  text-align: center;
}
section.costbuild {
  padding-bottom: 50px;
}
.houses-plans h4 {
  text-align: center;
  font-size: 37px;
  margin-bottom: 45px;
}
.specific {
  padding: 20px;
  background-color: #FEF0EB;
  margin: 10px;
  height: 100%;
  border-radius: 10px;
}
.specific strong {
  color: #f4652f;
}
.specific p {
  margin-top: 14px;
  font-size: 14px;
}
.houses-plans .col-lg-4, .houses-plans .col-lg-6 {
  margin-bottom: 20px;
}

section.blog h2 {
  text-align: center;
  padding: 34px 0;
  font-size: 33px;
}
.blog-cover {
  max-width: 378px;
  width: 100%;
  box-shadow: 0 4px 24px #ccc;
  margin: 20px auto;
}
.bg-content {
  padding: 20px;
}
.paging {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.page-link{
  color: #F46530;
}
.dte p {
  font-size: 13px;
}
.bg-content strong {
  font-size: 20px;
}
.bg-content p{
  margin-top: 20px;
}
.read-more-blg a {
  color: #F46530 !important;
}
.page-item.active .page-link{
  background-color: #F46530;
  border-color: #F46530;
}
.img-b {
  text-align: center;
}
.img-b img {
  width: 100%;
}
.blog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 19px 0;
}

.share-icons {
  display: flex;
}
.row.blog-inner img {
  width: 100%;
}
section.blog-details p {
  margin-top: 15px;
  font-size: 15px;
  margin-bottom: 34px;
}
.inner-content p{
  font-size: 15px;
}
section.blog-details h4 {
  margin-bottom: 24px;
  font-size: 26px;
}
.inner-content strong {
  font-size: 25px;
}
section.blog-details {
  padding: 49px;
}
.share-icons p{
  color: #F46530;
  margin-right: 20px;
}
.share-icons img{
  margin: 0 5px;
}
section.changes_orders h3 {
  font-size: 48px;
  margin: 50px 0;
  text-align: center;
}
.contin {
  background-color: #FEF0EB;
  padding: 22px;
  border-radius: 8px;
  margin-bottom: 50px;
}
.contin p{
  font-size: 15px;
}
.contin p a{
  color: #F46530;
}
section.modification h2 {
  margin: 60px 0;
  font-size: 47px;
  text-align: center;
}
.other-contents {
  margin-bottom: 34px;
  box-shadow: 0 0 7px #ccccccba;
  padding: 22px;
}
.other-contents h4 {
  margin-bottom: 20px;
}



.coverbg{
  background-color: #FEF0EB;
  padding: 32px;
  font-size: 15px;
  border-radius: 12px;
}
section.faq .accordion button{
  font-weight: 600;
}
.coverbg a{
  color: #F46530;
}
.contact-details a{
  color: #000;
  text-decoration: none;
}
section.includeplans h3 {
  font-size: 43px;
  text-align: center;
  margin: 49px 0;
}
.archietect {
  text-align: center;
}
.archietect div {
  text-align: center;
  margin-bottom: 33px;
  margin-top: 30px;
}
.buildcode img {
  width: 100%;
}
.buildcode .buil-content {
  background-color: #FEF0EB;
  padding: 37px;
}
.buil-content h5 {
  font-weight: 600;
  color: #F46530;
  font-size: 22px;
  margin-bottom: 21px;
}
.buildcode {
  padding: 20px;
}
.imp-extras {
  text-align: center;
  margin: 30px 0;
}
.imp-extras h4 {
  font-size: 29px;
}
.reverse {
  padding: 34px;
}
.reverse img{
  margin-bottom: 30px;
}


section.modification h2 {
  margin: 60px 0;
  font-size: 47px;
  text-align: center;
}
.other-contents {
  margin-bottom: 34px;
  box-shadow: 0 0 7px #ccccccba;
  padding: 22px;
}
.other-contents h4 {
  margin-bottom: 20px;
}



section.rewrad-program h2 {
  margin: 40px 0;
  font-size: 46px;
  text-align: center;
}
.outer-cover p span {
  color: #f4652f;
  font-weight: 700;
}
.outer-cover p {
  font-size: 15px;
}
.outer-cover {
  background-color: #FEF0EB;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}
section.rewrad-program .col-lg-4{
  margin-bottom: 40px;
}
.how_join {
  padding: 23px;
  box-shadow: 0 0 4px #ccc;
  margin-bottom: 30px;
}
.how_join ul li {
  list-style-type: none;
  font-size: 15px;margin-bottom: 10px;
}
.how_join ul li span{
  color: #f4652f;
  margin: 0 5px;
  font-weight: 700;
}
.how_join span{
  color: #f4652f;
  font-weight: 700;
}
.how_join ul{
  padding-left: 0;
  margin-top: 20px;
}
.wrapping-cover {
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 4px #ccc;
  margin: 0 10px;
  height: 100%;
  
}

section.membership h2 {
  margin: 53px 0;
  font-size: 44px;
}
section.membership img{
  width: 100%;
  margin-bottom: 50px;
}
section.membership ul li {
  font-size: 15px;
  margin-bottom: 10px;
}
.other-contents span{
  color: #F46530;
  font-weight: 700;
}
.other-contents a {
  text-decoration: underline;
}


section.designers h3 {
  font-size: 46px;
  margin: 50px 0;
  text-align: center;
}
.designer-about{
  background-color: #FEF0EB;
  padding: 20px;
  border-radius: 10px;
  margin-right: 30px;
}
.designer-about p {
  font-size: 14px;
}
section.designers h4 {
  font-size: 26px;
  font-weight: 600;
}
section.designers p {
  margin-bottom: 24px !important;
}
textarea#floatingTextarea2 {
  height: 100px;
}
section.designers .col-lg-8 .note{
  color: #F46530;
  margin-bottom: 20px;
}
.mb-3.upload {
  position: relative;
}
.mb-3.upload input::before{
  content: '';
  display: block;
  background-image: url('../images/publish.png');
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  top: 40px;
  z-index: 4;
  height: 20px;
}
.mb-3.upload input::after {
  content: '';
  background-color: #fff;
  width: 100%;
  display: block;
  height: 44px;
  position: absolute;
  left: 0px;
  top: 27px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

section.affiliate-program h2 {
  margin: 50px 0;
  font-size: 44px;
}
section.affiliate-program img{
  width: 100%;
  margin-bottom: 40px;
}
section.affiliate-program h4 {
  font-weight: 600;
  margin-bottom: 25px;
}
.other-contents ul li span a{
  color: #F46530;
}
.other-contents span a{
  color: #F46530;
}
section.affiliate-program h2 {
  margin: 50px 0;
  font-size: 44px;
}
section.affiliate-program img{
  width: 100%;
  margin-bottom: 40px;
}
section.affiliate-program h4 {
  font-weight: 600;
  margin-bottom: 25px;
}
.other-contents ul li span a{
  color: #F46530;
}
.other-contents span a{
  color: #F46530;
}
section.copyryt h3 {
  font-size: 45px;
  text-align: center;
  margin: 50px 0;
}
.copyryt .other-contents{
  margin-right: 30px;
}
section.copyryt h4 {
  margin-bottom: 21px;
  font-size: 30px;
}
section.copyryt p{
  font-size: 15px;
  margin-bottom: 30px;
}
.copyryt .form{
  margin-bottom: 50px;
}
.copyryt .form span{
  color: #F46530;
}
section.home_products h2 {
  font-size: 47px;
  margin: 50px 0;
}
.furnishing h5 {
  margin: 40px 0;
  text-align: center;
  font-size: 36px;
}
.furnishing img {
  margin-right: 21px;
}
.furnishing{
  text-align: center;
  margin-bottom: 50px;
}
.gallery h3{
  font-size: 50px;
  margin-bottom: 50px;
}


/*  //////////////////////////////////////// images full view modal code //////////////////////////////////////////////////////////////////////////////// */


/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button {
  background: transparent;
  border: none;
  color: #333;
  font-size: 20px;
}

button:focus {
  outline: none;
} */

/* Modal Container */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content */
.modal-content-image-full {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  background: none;
  border: none;
  color: red; /* Red color for the close icon */
  cursor: pointer;
  z-index: 10;
}

/* Image */
.modal-image-image-full {
  width: 800px;
  height: 500px;
  object-fit: cover; /* Ensures image fits within the modal without being cut off */
  max-height: 70vh; /* Prevents the image from being too large vertically */
}

/* Navigation Icons */
.prev-btn, .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  background: #F46530; /* Background color for buttons */
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
  width: 39px;
  height: 39px;
}

/* Left Navigation Button (Previous) */
.prev-btn {
  left: 10px;
}

/* Right Navigation Button (Next) */
.next-btn {
  right: 10px;
}

/* Optional: Hover effects for buttons */
.prev-btn:hover, .next-btn:hover {
  background-color: #F07F5B; /* Slightly darker background on hover */
}

/* Optional: Hover effect for the close button */
.close-btn:hover {
  color: #F07F5B; /* Change close icon color on hover */
}

/*  //////////////////////////////////////// images full view modal code //////////////////////////////////////////////////////////////////////////////// */

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears on top of other content */
}

/* Modal Content */
.modal-content {
  background: #ffffff; /* White background */

  width: 90%;
  max-width: 500px; /* Restrict modal size */
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for a polished look */
  text-align: center;
  animation: modalFadeIn 0.3s ease; /* Smooth fade-in animation */
  position: relative;
}

/* Modal Title */
.modal-content h4 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333333;
}

/* Modal Text */
.modal-content p {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 20px;
}
.archietect img {
  width: 100%;
}
/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
section.home_products img {
  width: 100%;
}

/* myaccount */
.acc-wrap h3 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 28px;
}
.prof-img {
  text-align: center;
  position: relative;
  width: 39%;
  margin: 0 auto;
  
}
.my-account .nav-link{
  color: #000 !important;
}
.my-account .nav-link:hover{
  border-color: 0 !important;
}
.my-account .nav-link.active {
  background-color: #f4652f !important;
  border-radius: 26px !important;
  color: #fff !important;
  padding: 10px 26px;
}
.my-account .img-h img {
  width: 100%;
}
.my-account .img-h{
  padding-top: 0;
}
.edit-ico {
  position: absolute;
  top: 109px;
  right: 143px;
  cursor: pointer;
}
ul#uncontrolled-tab-example {
  display: flex
;
  justify-content: center;
  margin-top: 44px;
  padding-bottom: 30px !important;
}
.nav-tabs .nav-link{
  margin: 0 10px;
}
div#uncontrolled-tab-example-tabpane-home {
  margin-bottom: 34px;
}
form.acc-form {
  max-width: 50%;
  margin: 0 auto;
  margin-top: 32px;
}
/* .my-acc-tabs {
  max-width: 60%;
  margin: 0 auto;
} */
.nav-tabs .nav-link:hover{
  border-color: transparent !important;
}
.edit-ico {
  position: absolute;
  top: 109px;
  right: 143px;
}
/* Buttons */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.btn-secondary {
  background: #cccccc;
  color: #333333;
}

.btn-secondary:hover {
  background: #b3b3b3;
}

.btn-danger {
  background: #e74c3c;
  color: white;
}

.btn-danger:hover {
  background: #c0392b;
}
.other-contents a{
  word-break: break-all;
}
/* Close Button */
.modal-content .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #999999;
  transition: color 0.2s ease;
}

.modal-content .close-btn:hover {
  color: #555555;
}
.delete-modal-cart{
  background-color: #ffffff; /* Set white background for the modal */

}
.review-wrap .img-h {
padding-top: 0 !important;
}
.plans-farms .img-h {
  padding-top: 0 !important;
}
.plans-farms .img-h img {
  width: 100% !important;
}
/* Modal Fade-in Animation */
@keyframes modalFadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}
.navbar-nav {
  display: none;
}
.navbar-nav.visible {
  display: flex;
}
@media (min-width: 768px) {
  .navbar-nav {
    display: block; /* Always show on desktop */
  }

  .navbar-toggler {
    display: none; /* Hide the toggle button on desktop */
  }
}



@media(max-width: 768px){
  .nav-head{
    padding: 10px 0;
  }
  .nav-tabs .nav-link {
    margin: 0 0px;
}
  form.acc-form{
    padding: 10px 18px;
  }
  form.acc-form{
    max-width: 100%;
  }
  .logo::before{
    display: none;
  }
  .collapse:not(.show){
    display: block !important;
  }
.closed {
    display: none !important;
  }
 .open {
    display: block !important;
  }
  .features-wrap{
    margin-top: 34px;
  }
  .plans-farms .img-h img {
    width: 100% !important;
}
.plans-farms .img-h{
  padding-top: 0 !important;
}
  .banner-search{
    flex-direction: column;
  }
  .banner-search .bed{
    max-width: 100%;
    width: 100%;
    margin-right: 15px;
    margin-bottom: 11px;
  }
  .drop-list .dropdown-menu.show{
    position: unset !important;
    transform: unset !important;
  }
}
@media(max-width: 660px){
  section.home_products h2{
    font-size: 30px;
  }
  section.rewrad-program h2{
    font-size: 30px;
  }
  header .top-cont{
    margin-left: 0 !important;
  }
  nav.navbar.navbar-expand-lg.navbar-light {
    margin-top: 9px;
}
section.modification h2{
  font-size: 30px;
}
  .logo img{
    width: 120px;
  }
  .account, .fav, .cart{
    background-color: #fff;
  }
  button.btn.btn-outline-primary.phno{
    background-color: #fff;
  }
  header .top-cont{
    width: 100%;
    max-width: 100%;
    background-color: #FEF0EB;
  }
  .nav-tpp button span {
    display: none;
}
}

@media(max-width: 460px){
  section.ourstory .row{
    padding: 22px;
  }
  .left-ban-text h4{
    font-size: 26px;
  }
  .search .wrap{
    max-width: 100%;
  }
  .rev-wrap{
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .wrap{
    max-width: 100%;
  }
  .img-h img{
    width: 100%;
  }
  .slideHorizontal___1NzNV{
    height: 300px;
  }
  .cont-details div{
    margin-bottom: 14px;
  }
  .account span {
    display: none;
}
  .features-wrap .history{
    max-width: 100%;
  }
  
  section.ourprize img {
    width: inherit;
}
.m-5.mobil{
  margin: 13px !important;
}
.m-5.mobil h2{
  font-size: 30px;
}

.looking-plan input{
  width: 100%;
}
.filters{
  margin: 0;
}
section.costbuild img {
  width: 100%;
}
.account p {
  display: none;
}
.account{
  width: 32px;
}
button.btn.btn-secondary{
  padding: 10px 15px;
}
.cont-details{
  flex-direction: column;
}
.nav-head{
  padding-right: 0;
}
footer{
  padding: 72px 20px 30px 20px;
}
.content-wrap h4{
  padding-right: 0;
}
.content-wrap p{
  padding-right: 0;
}
.img-wrap img {
  margin-top: 20px;
}
.review-wrap{
  flex-direction: column;
}
.blog-cover img {
  width: 100%;
}
section.changes_orders h3 {
  font-size: 27px;
}
.content-review p{
  padding-right: 0rem;
}
.furnishing img{
  margin-bottom: 20px;

}
.top-heading h2{
  font-size: 30px;
}
.contact-form{
  padding: 21px;
}
.login-content{
  padding-top: 27px;
}
button.btn.btn-secondary.search {
  padding: 4px 10px;
}
button.btn.btn-outline-primary.phno{
  padding: 4px 5px;
}
section.login-content .row {
  flex-direction: column-reverse;
}
.pt-content{
  margin-top: 30px;
}
.login-form{
  padding: 0px 1rem;
}
.login-form h2{
  text-align: center;
}
.logo img{
  width: 103px;
}
.account, .fav, .cart{
  box-shadow: unset;
}
}
@media(max-width:375px){
  button.btn.btn-outline-primary.phno{
    display: none;
  }
  .pt-content img{
    margin-right: 0;
    width: 100%;
    margin-top: 20px;
  }
  .features-wrap .history{
    max-width: 100%;
  }
  section.ourstory .row, section.ourprize .container{
    padding: 20px;
  }
}