
/* Checkout Form Container */
.checkout-form {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Header */
.checkout-form h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Card Element Styling */
.stripe-card-element {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Submit Button */
.checkout-form button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-form button:hover {
  background-color: #0056b3;
}

.checkout-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Error Message */
.checkout-form .error {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 15px;
  text-align: center;
}

/* Success Message */
.checkout-form .success {
  color: #28a745;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
}


/* .App {
  text-align: center;
} */
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



 */


/* CSS FOR THE CARD ELEMENT AND CHECKOUT PAGE  */


 body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.checkout-container {
  display: flex;
  height: 100vh;
}

.left-panel {
  background-color: #5c6bc0;
  color: white;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.branding .logo {
  width: 50px;
  height: 50px;
}

.branding h2 {
  font-size: 24px;
  margin-top: 10px;
}

.description {
  font-size: 18px;
  line-height: 1.5;
}

.footer p,
.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.language {
  margin-top: 10px;
  cursor: pointer;
}

.right-panel {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.card-container {
  width: 400px;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
}

.stripe-card-element {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.pay-button {
  width: 100%;
  padding: 10px;
  background-color: #5c6bc0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.pay-button:disabled {
  background-color: #ccc;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

/* CSS FOR THE CARD ELEMENT AND CHECKOUT PAGE  */